import React from 'react'
import { Link } from 'gatsby'
import {getConversion} from '../../api/images'

const ServiceCard = ({ service }) => {
    const { media, name, slug, summary } = service

    return (
        <div className='row mb-3'>
            <div className='col-3'>
                {media[0] ?
                    <img src={getConversion(media[0])} alt='service' 
                    style={{width: '100%', height: 'auto'}}
                    />
                    :
                    null
                }
            </div>
            <div className='col-9'>
                <Link to={`/service-details?slug=${slug}`}>
                    <h6>{name}</h6>
                    <small>{summary}</small>
                </Link>
            </div>
        </div>
    )
}

export default ServiceCard